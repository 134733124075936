<template>
  <div class="equipment-affix-wrapper">
    <van-nav-bar
      title="安全附件"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-affix-form">
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-affix-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div
          v-for="item in list"
          :key="item.id"
          class="affix-item"
          @click="onToDetail()"
        >
          <span class="affix-interval" :class="`_${item.equipmentStatus}`">{{
            item.equipmentStatusName
          }}</span>
          <div class="affix-title">{{ item.machineNo }}({{ item.name }})</div>
          <div class="affix-bottom">
            <span>{{ item.levelName }}</span>
            <span class="affix-mode">{{ item.categoryName }}</span>
          </div>
          <van-icon class="document-arrow" name="arrow" />
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getEquipmentRelationSafety } from "@/api/basics/equipment";
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10
      },
      list: [],
      total: 0,
      loading: false,
      finished: false
    };
  },
  created() {},
  methods: {
    onToDetail() {
      this.$router.push({
        name: "equipmentledgerInfo",
        params: { id: this.id }
      });
    },
    onLoad() {
      this.getData();
    },
    getData() {
      this.getEquipmentRelationSafety();
    },
    getEquipmentRelationSafety() {
      this.loading = true;
      getEquipmentRelationSafety(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-affix-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-affix-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
  }
  .equipment-affix-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .affix-item {
      position: relative;
      padding: 0 16px;
      .document-arrow {
        position: absolute;
        right: 16px;
        bottom: 18px;
        font-size: 18px;
      }
      .affix-interval {
        position: absolute;
        right: 16px;
        color: #333;
        text-align: center;
        border-radius: 2px;
        font-size: 12px;
        padding: 4px 8px;
        &._1 {
          background-color: #3c9e00;
          color: #fff;
        }
        &._2 {
          background-color: #fac967;
          color: #fff;
        }
        &._3 {
          background-color: #59cbc0;
          color: #fff;
        }
        &._4 {
          background-color: #e1362f;
          color: #fff;
        }
        &._5 {
          background-color: #f69b64;
          color: #fff;
        }
      }
      .affix-bottom {
        margin-top: 18px;
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        align-items: center;
        .affix-mode {
          background-color: rgba(254, 119, 11, 0.1);
          color: #fe770b;
          padding: 4px 8px;
          border-radius: 2px;
          margin-left: 10px;
        }
        .affix-lastdate {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
